const INITIALSTATE = {
  userList: {}
};

const UserReducer = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case 'GET_USER':
            return {
                ...state,
                userList: action.getUserList
            };
        default:
            return state;
    }
}

export default UserReducer