// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth/reducer'
import layout from './layout/reducer'
import navbar from './navbar/reducer'
import announcement from './announcement/reducer'
import unit from './unit/reducer'
import user from './user/reducer'
import categoryUnit from './categoryUnit/reducer'
import categoryUnitContent from './categoryUnitContent/reducer'
import test from './test/reducer'

const rootReducer = combineReducers({
  auth,
  layout,
  navbar,
  announcement,
  unit,
  user,
  categoryUnit,
  categoryUnitContent,
  test
})

export default rootReducer