const initialState = {
  getAllUnit: []
};

const UnitReducers = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_UNIT':
      return { ...state, getAllUnit: action.allData };
    default:
      return state;
  }
};

export default UnitReducers;
