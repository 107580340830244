const INITIALSTATE = {
    categoryUnit: [],
    categoryUnitList: [],
    categoryUnitCompletedList: [],
    categoryUnitNotCompletedList: [],
    categoryUnitDetail: {},
    categoryUnitLoadingData:true
};

const CategoryUnitReducer = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case 'GET_CATEGORY_UNIT':
            return {
                ...state,
                categoryUnit: action.categoryUnit
            };
        case 'GET_CATEGORY_UNIT_BY_USERID':
            return {
                ...state,
                categoryUnitList: action.categoryUnitList,
                categoryUnitLoadingData:action.categoryUnitLoadingData,
                categoryUnitCompletedList: action.categoryUnitCompletedList,
                categoryUnitNotCompletedList: action.categoryUnitNotCompletedList
            };
        case 'GET_CATEGORY_UNIT_BY_UNITID':
            return {
                ...state,
                categoryUnitDetail: action.categoryUnitDetail
            };
        default:
            return state;
    }
}

export default CategoryUnitReducer
