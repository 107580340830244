const INITIALSTATE = {
  announcementList: [],
  announcementType: 0,
  getAnnouncementRes: {},
  announcementLoadingData: true
};

const AnnouncementReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case 'GET_USER_ANNOUNCEMENT':
      return {
        ...state,
        announcementList: action.getAnnouncement,
        announcementType: action.announcementType,
        getAnnouncementRes: action.getAnnouncementRes,
        announcementLoadingData: action.announcementLoadingData
      };
    default:
      return state;
  }
}

export default AnnouncementReducer
