const inititialState = {
    getTest: [],
    getTestUserId: [],
    getUserTestResult:[],
    userNote:[],
    average:[],
    testName:[],
    testLoadingData:true,
    getUserTestLoadingData:true,
    getTestUserIdLoadingData:true
}

const TestReducer = (state = inititialState, action) => {
    switch (action.type) {
        case 'GET_TEST_BY_ID':

            return {
                ...state,
                getTest: action.getTest
            }

        case 'GET_TEST_BY_USER_ID':
            return {
                ...state,
                getTestUserId: action.getTestUserId,
                getTestUserIdLoadingData:action.getTestUserIdLoadingData
            }
        case 'GET_USER_TEST_RESULT':
            return {
                ...state,
                getUserTestResult: action.getUserTestResult,
                getUserTestLoadingData:action.getUserTestLoadingData
            }
        case 'DASHBOARD_TEST_INFO':
            return {
                ...state,
                userNote: action.userNote,
                average: action.average,
                testName: action.testName,
                testLoadingData:action.testLoadingData
            }

        default:
            return state
    }

}

export default TestReducer