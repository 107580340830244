const INITIALSTATE = {
    contentReport: {},
    contentList: [],
    videoList: [],
    contentDetailx: {},
    completedContentList: [],
    contentreportLoadingData: true,
    getlink: {}
}

const UnitCategoryContentReducer = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case 'CONTENT_REPORT':
            return {
                ...state,
                contentreportLoadingData: action.contentreportLoadingData,
                contentReport: action.contentReport,
            };
        case 'GET_BY_CATEGORY_UNIT_ID':
            return {
                ...state,
                contentList: action.contentList,
                videoList: action.videoList
            }
        case 'GET_CONTENT_BY_ID':
            return {
                ...state,
                contentDetailx: action.contentDetailx
            }
        case 'GET_COMPLETED_CONTENT_BY_USERID':
            return {
                ...state,
                completedContentList: action.completedContentList
            }
        case 'GET_VIDEO_LINK':
            return {
                ...state,
                getlink: action.getlink
            }

        default:
            return state;
    }
}

export default UnitCategoryContentReducer